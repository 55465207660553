import styled from "styled-components"

export const IframeMain = styled.div`
.iframe-container-box{
   padding: 110px 0 80px;
}
  .iframe-container {
    padding-top:100px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    @media (max-width: 768px) {
        padding: 140px 0 60px;
    }
    
  }
  .iframe-container.vif-pd{ padding: 210px 0px 80px; }
  .vimeo-iframe {
    width: 740px;
    height: 460px;
    @media (max-width: 768px) {
        width: 100%;
        height: 100%;
    }
  }
  h2 {
    font-size: 38px;
  }
  .title-container {
    margin-bottom: 0;
  }
`