import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import { ModalContactContent } from "../elements/modal-contact-content";
import { TextPageContent } from "../elements/modal-text-page-content";
import { ModalGroupMain } from "../styles/modal-group.style";
import { Modal } from "./modal";

const BHDigitalModal = ({
  modalProps,
  digitalassetsacf,
}) => {
  const { modalStatus } = modalProps;

  const {
    wpgraphql: {
      themeSettings: {
        themesettingsacf
      }
    }
  } = useStaticQuery(graphql`
    query {
      wpgraphql {
        themeSettings {
          themesettingsacf {
            contactIrEmail
            contactIrLabel
            otherEnquiriesEmail
            otherEnquiriesLabel
            otherEnquiriesPhoneLink
            otherEnquiriesPhoneText
            pressEnquiriesGroupName
            pressEnquiriesLabel
            pressEnquiriesUkEmail
            pressEnquiriesUkEmailText
            pressEnquiriesUkPhoneLink
            pressEnquiriesUsaEmail
            pressEnquiriesUkPhoneText
            pressEnquiriesUsaEmailText
            pressEnquiriesUsaPhoneLink
            pressEnquiriesUsaPhoneText

            disclaimerRejectButtonText
            disclaimerCookieFooterTitle
            disclaimerCookieFooterContent
            disclaimerCookieFooterLinkText
            disclaimerAcceptButtonText
            disclaimerContactSubtitle
            disclaimerContactTitle
            disclaimerCookiesTitle

            disclaimerModalTitle
            prependDisclaimerContent {
              text
              title
            }
          }
        }
      }
    }
  `);

  if (typeof window !== 'undefined') {
    if (modalStatus?.open) {
      document.body.classList.add('modal-open');
    } else {
      document.body.classList.remove('modal-open');
    }
  }

  const isSSR = typeof window === "undefined";

  if (!modalStatus?.open && !isSSR) {
    return null
  }

  const riskContent = { introContent: [], sections: digitalassetsacf?.riskFactorsModalData?.content };
  const disclContent = {
    introContent: [], sections: (digitalassetsacf?.disclosuresModalData?.content || []).map((item, i) => {
      // NOTE: no clue why client doesn't want this tittle to be hidden here
      return { ...item, title: i === 0 ? '' : item?.title }
    })
  };
  const mixedContent = { introContent: digitalassetsacf?.disclosuresModalData?.content, sections: digitalassetsacf?.riskFactorsModalData?.content };

  return (
    <ModalGroupMain>
      {modalStatus?.type === 'risk' ?
        <Modal hasBackButton={false} modalClass={`${modalStatus?.acceptType === 'withAccept' ? 'modal-sm' : ''} modal-has-actions sm-s-title modal-h-md`}>
          <h2 className={'intro-title'}>
            {digitalassetsacf?.riskFactorsModalData?.title}
          </h2>
          <TextPageContent data={riskContent} hideSectionNumbers={true} />
          <div className="modal-actions-wrap">
            {modalStatus?.acceptType !== 'withAccept' && <button className="rect-btn" onClick={() => modalProps?.closeModal()}>
              {digitalassetsacf?.modalCloseButtonLabel}
            </button>}
          </div>
        </Modal>
        : modalStatus?.type === 'disclosure' ?
          <Modal hasBackButton={false} modalClass={`${modalStatus?.acceptType === 'withAccept' ? 'modal-sm' : ''}  modal-has-actions sm-s-title modal-h-md`}>
            <h2 className={'intro-title'} style={{ textTransform: 'capitalize' }}>
              {((digitalassetsacf?.disclosuresModalData?.content || [])?.[0]?.title || '').toLowerCase()}
            </h2>
            <TextPageContent data={disclContent} hideSectionNumbers={true} />
            <div className="modal-actions-wrap">
              {modalStatus?.acceptType !== 'withAccept' && <button className="rect-btn" onClick={() => modalProps?.closeModal()}>
                {digitalassetsacf?.modalCloseButtonLabel}
              </button>}
            </div>
          </Modal>
          : modalStatus?.type === 'contact' ?
            <Modal
              onBackClick={() => {
                modalProps?.changeModal('')
              }}
            >
              <h2 className="intro-title">
                {themesettingsacf?.disclaimerContactTitle}
              </h2>
              <p className="modal-subtitle">
                {themesettingsacf?.disclaimerContactSubtitle}
              </p>
              <div className="modal-content">
                <ModalContactContent contactDetails={themesettingsacf} />
              </div>
            </Modal>
            :
            <Modal hasBackButton={false} modalClass={`${modalStatus?.acceptType === 'withAccept' ? 'modal-sm' : ''} modal-has-actions sm-s-title`}>

              <h2 className={'intro-title'}>
                {digitalassetsacf?.disclosuresModalData?.title}
              </h2>

              <TextPageContent data={mixedContent} sectionsHeading={digitalassetsacf?.riskFactorsModalData?.title} hideSectionNumbers={true} />

              <div className="modal-actions-wrap">
                {modalStatus?.acceptType === 'withAccept' && <button className="rect-btn" onClick={() => modalProps?.acceptAndCloseModal()}>
                  {digitalassetsacf?.modalAcceptButtonLabel}
                </button>}
                {modalStatus?.acceptType === 'withAccept' && <button className="link-btn link-btn-cta" onClick={() => modalProps?.changeModal('contact')}>
                  {themesettingsacf?.disclaimerRejectButtonText}
                </button>}

                {/* CLOSE ONLY */}
                {modalStatus?.acceptType !== 'withAccept' && <button className="rect-btn" onClick={() => modalProps?.closeModal()}>
                  {digitalassetsacf?.modalCloseButtonLabel}
                </button>}
              </div>

              {modalStatus?.acceptType === 'withAccept' && <div className="modal-footer-wrap">
                <h3 className="modal-footer-title">
                  {digitalassetsacf?.modalFooterTitle}
                </h3>
                <p className="modal-footer-content">
                  {digitalassetsacf?.modalFooterText}
                </p>
              </div>}

            </Modal>
      }
    </ModalGroupMain>
  )
}

export default BHDigitalModal