// import React from "react"
import React, { useState } from 'react';
import Layout from "../components/layout"
import SEO from "../components/seo"
import { IframeMain } from "../styles/iframe.styles"
import { graphql } from "gatsby"
import { SectionTitle } from "../elements/section-title"
import { HexagonTitle } from "../elements/hexagon-title"
import { HexagonTitleMain } from "../styles/common.styles"
import BHDigitalModal from "../components/bh-digital-modal"
import useModalProps from "../helpers/use-modal-props"


const data = [
    {
      videos: [
        {
            vimeoCode: "832176817",
          title: "BH Digital – Digital Asset Ecosystem",
        },
        // {
        //     vimeoCode: "832176817",
        //   title: "BH Digital – Digital Asset Ecosystem",
        // },
      
      ],
      heading: "Brevan Howard Digital - An Introduction",
    },
  
    ,
    {
      videos: [
        {
            vimeoCode: "832176689",
          title: "Digital Series – An Introduction with Coleen and Peter",
        },
      
      ],
      heading: "Crypto Bytes – Snackable sessions with BH Digital",
    },
  
    ,
    {
      videos: [
        {
            vimeoCode: "832176566",
          title: "BH Digital – ODD and Custody with Cem Paya and Ryan Taylor",
        },
        // {
        //     vimeoCode: "831426536",
        //   title: "BH Digital – Counterparty Risk with Ivan Chen",
        // },
      
      ],
      heading: "Brevan Howard Digital – Business Focus Series:",
    },
  
  ];



 function VideoIframe() {



      return (
        <Layout style="padding-top 20px !important" >
            
          
            <SEO title="Video" />

            <IframeMain>
                
            <div   class="iframe-container-box">
            
            {data.map((section) => (

                    <div   class="iframe-container">
                    <div>
                    <SectionTitle variation="title-container" text={section.heading} responsive={[{ breakpoint: 767, charLimit: 6 }]} />
            
                    </div>

                    {section.videos.map((video) => (
                        <div>
                    <HexagonTitleMain style={{textAlign: 'center', marginBottom: "2rem", marginTop: "20px" }}>
                    <span className="hexagon-wrap">
                    </span>
                    <span className="hexagon-text">{video.title}</span>
                    </HexagonTitleMain>

                    <iframe class="vimeo-iframe" src={'https://player.vimeo.com/video/' +video.vimeoCode+'?h=13cdf6f150'}  width="740" height="460" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen></iframe>
                    </div>
                    

             ))}

                </div>  


            ))}
            </div>
                        </IframeMain>

            </Layout>

            
            
    );
   }

export default VideoIframe;